// READING TIME
export default function readTime() {
	const wordcounts = document.querySelectorAll('.meta-readtime');

	if (wordcounts) {
		// console.log('readTime');
		wordcounts.forEach(function (value) {
			let readTime = Math.round(value.innerHTML / 200);

			if (readTime == 0) {
				readTime = '< 1';
			}

			value.innerHTML = `${readTime} min read`;
			value.classList.add('show');
		});
	}
}
