// Yips
export default function yips() {
	const entryYips = document.querySelectorAll('.post-entry__yip');

	if (entryYips) {
		// console.log('yips');
		const yipArray = [
			'You waited for this huh?',
			'1337 h4x0r',
			'すごい!',
			'おいしい!!',
			'Maybe this?',
			"let's give this a spin",
			'Read this one?',
			'I’m sorry Dave, I’m afraid I can’t do that',
			'Clicky click click',
		];

		entryYips.forEach(function (yip, i) {
			const randomYip = yipArray[Math.floor(Math.random() * yipArray.length)];

			if (i == 0) {
				yip.innerText = "Ouch that's hot";
			} else if (Math.floor(Math.random() * 30) == 13) {
				yip.innerText = "💀 oh that's unlucky";
				yip.style.fontStyle = 'normal';
			} else if (Math.floor(Math.random() * 30) == 0) {
				yip.innerText = "⭐️ that's a rare one!";
				yip.style.fontStyle = 'normal';
			} else {
				yip.innerText = randomYip;
			}

			if (yip.innerText == 'Stop!' || yip.innerText == 'STOP!') {
				yip.style.background = 'red';
				yip.style.color = 'white';
				yip.style.transitionDelay = '0ms';
				yip.parentNode.addEventListener('mouseover', () => {
					setTimeout(function () {
						yip.style.removeProperty('background');
						yip.style.removeProperty('color');
						yip.innerText = 'Jokes, go ahead!';
					}, 1500);
				});
			}
		});
	}
}
