const body = document.querySelector('body');
const main = document.querySelector('main[data-router-wrapper]');
const menuToggle = document.querySelector('#menu-toggle');
const inventoryToggle = document.querySelector('#inventory-toggle');
const settingsToggles = document.querySelectorAll('.settings-toggle');
const darkToggles = document.querySelectorAll('.dark-toggle');
const nothingToggles = document.querySelectorAll('.nothing-toggle');

let darkMode = JSON.parse(localStorage.getItem('dark'));
let nothingMode = JSON.parse(localStorage.getItem('nothing'));
let firstload = JSON.parse(localStorage.getItem('firstload'));

export function toggles() {
	// console.log('toggles');

	if (firstload === null) {
		JSON.stringify(localStorage.setItem('firstload', true));
	} else {
		body.classList.remove('firstload');
	}

	menuToggle.addEventListener('click', (e) => {
		body.classList.toggle('menu-open');
		body.classList.remove('inventory-open');
		e.stopPropagation();
	});

	inventoryToggle.addEventListener('click', (e) => {
		body.classList.toggle('inventory-open');
		body.classList.remove('menu-open');
		e.stopPropagation();
	});

	main.addEventListener('click', (e) => {
		body.classList.remove('menu-open');
		body.classList.remove('inventory-open');
		e.stopPropagation();
	});

	settingsToggles.forEach((settingsToggle) => {
		settingsToggle.addEventListener('click', (e) => {
			body.classList.toggle('settings-open');
			e.stopPropagation();
		});
	});

	darkToggles.forEach((darkToggle) => {
		darkToggle.addEventListener('click', (e) => {
			body.classList.toggle('dark-mode');
			darkMode = !darkMode;
			JSON.stringify(localStorage.setItem('dark', darkMode));
			console.log(`Dark mode: ${darkMode}`);
			e.stopPropagation();
		});
	});

	nothingToggles.forEach((nothingToggle) => {
		nothingToggle.addEventListener('click', (e) => {
			body.classList.toggle('nothing-mode');
			nothingMode = !nothingMode;
			JSON.stringify(localStorage.setItem('nothing', nothingMode));
			console.log(`Nothing mode: ${nothingMode}`);
			e.stopPropagation();
		});
	});
}

export function togglesClose() {
	// console.log('toggles close');

	body.classList.remove('menu-open');
	body.classList.remove('inventory-open');
	body.classList.remove('settings-open');
}
