// File: custom-renderer.js
// Import Highway
import Highway from '@dogstudio/highway';
import billb from '../modules/homebillb.js';
import lastfm from '../modules/homelastfm.js';
import snow from '../modules/snow.js';

class HomeRenderer extends Highway.Renderer {
	// Hooks/methods
	onEnter() {
		billb();
		// snow();
		// lastfm();
	}
	onLeave() {}
	onEnterCompleted() {}
	onLeaveCompleted() {}
}

// Don`t forget to export your renderer
export default HomeRenderer;
