// Vendor
import Rellax from 'rellax';
import Highway from '@dogstudio/highway';
import HomeRenderer from './highway/home-renderer.js';
import DefaultTransition from './highway/default-transition.js';

// Modules
import { toggles, togglesClose } from './modules/toggles.js';
import readTime from './modules/readtime.js';
import yips from './modules/yips.js';
//import billb from './modules/homebillb.js';

const H = new Highway.Core({
	renderers: {
		home: HomeRenderer,
	},
	transitions: {
		default: DefaultTransition,
	},
});

// console.log('firstLoad');
if (document.querySelector('.rellax')) {
	// console.log('init rellax');
	var rellax = new Rellax('.rellax');
}
toggles();
readTime();
yips();

let logo = document.querySelector('.site-header__logo svg');

logo.onanimationend = function () {
	// console.log('Animation stopped');
	document.querySelector('.site-header__logo').classList.remove('anim-in');
};

H.on('NAVIGATE_OUT', () => {
	togglesClose();
});
// This event is sent everytime a `data-router-view` is added to the DOM Tree
H.on('NAVIGATE_IN', ({ to, trigger, location }) => {
	// console.log('navigate in');
	window.scrollTo(0, 0);
	readTime();
	yips();

	document.querySelector('.site-header__logo').blur();

	if (rellax) {
		// console.log('refresh rellax');
		rellax.refresh();
	} else {
		if (document.querySelector('.rellax')) {
			// console.log('init rellax');
			var rellax = new Rellax('.rellax');
		}
	}
});
