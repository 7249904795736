export default function billb() {
	const billb = document.querySelector('.homepage-billboard');
	if (billb) {
		//console.log('homebillb');
		const ImageArray = [
			// ['/assets/images/2bcasual', 'homepage-billboard--2b'],
			// ['/assets/images/raine-wide', 'homepage-billboard--raine'],
			// ['/assets/images/slurp-wide', 'homepage-billboard--slurp'],
			// ['/assets/images/lift-wide', 'homepage-billboard--lift'],
			// ['/assets/images/hand-wide', 'homepage-billboard--hand'],
			['/assets/images/25aug', 'homepage-billboard--full'],
			['/content/images/2020/12/nia-city-001', 'homepage-billboard--full'],
			['/assets/images/matoi-tr', 'homepage-billboard--ryuko'],
			['/assets/images/rebecca-tr', 'homepage-billboard--rebecca'],
			// ['/assets/images/hack-wide', 'homepage-billboard--hack'],
			// ['/assets/images/headphones-wide', 'homepage-billboard--headphones'],
			['/assets/images/towernight', 'homepage-billboard--full'],
			// ['/assets/images/xmas', 'homepage-billboard--full'],
			// ['/assets/images/xmas2', 'homepage-billboard--full'],
			['/content/images/2020/12/nia-run', 'homepage-billboard--run'],
			['/assets/images/floodlight', 'homepage-billboard--floodlight'],
		];

		function getRandomImage() {
			const picture = document.querySelector('#home-bb');
			var num = Math.floor(Math.random() * ImageArray.length);
			var rand = ImageArray[num];
			let image = document.querySelector('#home-bb img');
			const text = document.querySelector('.homepage-billboard__inner');

			if (
				rand[1] === 'homepage-billboard--run' ||
				rand[1] === 'homepage-billboard--floodlight'
			) {
				picture.innerHTML = `
					<img src="${rand[0]}.gif" style="opacity: 0; transition: all 500ms ease 200ms;" alt="hero illustration">
				`;
			} else {
				picture.innerHTML = `
					<source srcset="${rand[0]}.webp" type="image/webp" />
					<img src="${rand[0]}.jpg" style="opacity: 0; transition: all 500ms ease 200ms;" alt="hero illustration">
				`;
			}

			billb.classList.add(rand[1]);

			image = document.querySelector('#home-bb img');

			if (image.complete) {
				image.style.opacity = 1;
			} else {
				if (image.addEventListener) {
					image.addEventListener('load', function() {
						image.style.opacity = 1;
					});
				} else {
					image.attachEvent('onload', function() {
						image.style.opacity = 1;
					});
				}
			}
		}
		getRandomImage();
	}
}
