export default function lastfm() {
	if (document.querySelector('.lastfm-body')) {
		// console.log('homelastfm');
		let albumsList = [];
		let html = '';
		const lastFM = document.querySelector('.lastfm-body');
		function template(albums) {
			for (i = 0; i < albums.length; i++) {
				html +=
					'<a target="_blank" rel="noopener" href="' + albums[i].url + '">';
				html +=
					'<img alt="' +
					albums[i].name +
					' by ' +
					albums[i].artist.name +
					'" src="' +
					albums[i].image[2]['#text'] +
					'">';
				html += '</a>';
			}
			return html;
		}

		var xhr = new XMLHttpRequest();
		xhr.open(
			'GET',
			'https://ws.audioscrobbler.com/2.0/?method=user.getTopAlbums&user=nervewax&period=7day&limit=1&api_key=b44f63a83788af3721cafcefb3dbc16a&format=json',
		);
		xhr.onload = function (data) {
			if (xhr.status === 200) {
				const response = JSON.parse(data.srcElement.response);
				albums = response.topalbums.album;
				lastFM.innerHTML = template(albumsList);
			} else {
				console.log('failed');
			}
		};
		xhr.send();
	}
}
